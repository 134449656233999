import { useState, useEffect, useCallback } from 'react'

const useForm = () => {
  const [submitting, setSubmitting] = useState(false)
  const [submitted, setSubmitted] = useState(false)
  const [target, setTarget] = useState({})
  const [success, setSuccess] = useState(false)

  const handleSubmit = e => {
    e.preventDefault()
    setSubmitting(true)
    setTarget(e.target)
    setSubmitted(true)
  }

  const sendValues = useCallback(() => {
    const xhr = new XMLHttpRequest()
    const data = new FormData(target)

    xhr.open(target.method, target.action)
    xhr.setRequestHeader('Accept', 'application/json')
    xhr.onreadystatechange = () => {
      setSubmitting(false)
      if (xhr.readyState !== XMLHttpRequest.DONE) return
      if (xhr.status === 200) {
        target.reset()
        setSuccess(true)
      } else {
        setSuccess(false)
      }
    }
    xhr.send(data)
  }, [target])

  useEffect(() => {
    if (submitted) {
      sendValues()
    }
  }, [submitted, sendValues])

  return {
    handleSubmit,
    submitting,
    submitted,
    success
  }
}

export default useForm
